<template>
  <div class="c-app flex-row align-items-center mainbg">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="8">
          <CCardGroup>
            <CCard class="p-4 login-lft">
              <CCardBody>
                <CForm>
                  <CRow>
                    <img
                    src="img/logo/logo.png"
                    style="height:70px;"
                  />
                  <img
                    src="img/logo/fraudavoid_ logo.png"
                    style="height:70px; width:180px;"
                  />
                  </CRow>
                  <h4 class="mt-2">Sign In to your account</h4>
                  <CInput
                    placeholder="Email"
                    autocomplete="username email"
                    v-model="input.email"
                  >
                    <template #prepend-content><CIcon name="cil-user"/></template>
                  </CInput>
                  <CInput
                    placeholder="Password"
                    type="password"
                    autocomplete="curent-password"
                    id="password"
                    v-model="input.password"
                    v-on:keyup.enter="login"
                  >
                  <template #prepend-content>
                    <span @click="togglePassword('password')">
                    <CIcon v-show="showPassword == true" name="cil-lock-unlocked"/>
                    <CIcon v-show="showPassword == false" name="cil-lock-locked"/>
                  </span>
                  </template>
                  </CInput>
                  <CRow>
                    <CCol class="text-left">
                      <CButton color="link" class="px-0" @click="gotoPage('forgotpassword')">Forgot password?</CButton>
                    </CCol>
                    <CCol class="text-right">
                       <CButton color="link" class="d-lg-none" @click="gotoPage('register')">Register now!</CButton>
                    </CCol>
                  </CRow>
                  <CRow class="text-center mt-3">
                    <CCol>
                      <CSpinner color="info" v-if="loading"/>
                      <CButton color="primary" class="px-4" @click="login()" :disabled=loading v-else>{{loading?'Loading...':'Login'}}</CButton>
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
            <CCard
              color="primary"
              text-color="white"
              class="text-center py-5 d-md-down-none login-rgt"
              body-wrapper
            >
              <CCardBody>
                <img
                    src="img/logo/logo.png"
                    style=""
                  />
                
                <p>Glad to see you again
                While you were away fraudAvoid has been monitoring your traffic for fraudulent patterns and making sure you see if your revenues are at risk</p>
                <CButton
                  color="light"
                  variant="outline"
                  size="lg"
                   @click="gotoPage('register')"
                   class="pl-3 pr-3"
                >
                  Register Now!
                </CButton>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import LoginService from './../../services/loginControlService'
import helperFunction from '../../helper/helperFunction'
export default {
  name: 'Login',
  data() {
            return {
                loading: false,
                input: {
                    email: "",
                    password: ""
                },
                auth:'',
                showPassword:false,
            }
        },
      mounted(){ 
        if(this.$route.query.wildLogin){
          window.localStorage.setItem('user', this.$route.query.user)
          window.localStorage.setItem('email', this.$route.query.email)
          window.localStorage.setItem('status', this.$route.query.status)
          window.localStorage.setItem('iwitness', this.$route.query.iwitness)
        }
        if(window.localStorage.getItem('user') && window.localStorage.getItem('user') != 'null'){
          this.$router.push('/')
        }
      },
        methods: {
          gotoPage(txt){
            switch(txt){
              case 'register' : this.$router.push("/pages/register")
              break;
              case 'forgotpassword' : this.$router.push("/pages/forgotpassword")
              break;
            }
           },
         async login() {
              if(this.input.email == "" && this.input.password == ""){
                Swal.fire({title: 'Error!',text: "please enter username & password",icon: 'error',confirmButtonText: 'Close',position: 'top-end',showConfirmButton: false,timer: 1000})
                // this.errorNotify('Error','please enter username & password','danger')
              }
              else{
                this.loading = true
                let response
                let encPassword = await helperFunction.encryptText(this.input.password)
                let payload = {
                  email: this.input.email,
                  password: encPassword
                }
                try {
                  response = await LoginService.login(payload)
                } catch(e){
                  console.error("Error in login")
                  console.log(e)
                }
              if (response.result == true) {
                window.localStorage.setItem('user', response.message.role)
                window.localStorage.setItem('email', response.message.email)
                window.localStorage.setItem('status', response.message.isactive)
                window.localStorage.setItem('iwitness', response.message.iwitness)
                window.localStorage.setItem('isViewLog', response.message.isViewLog)
                if(response.message.logoutTimeout){
                  window.localStorage.setItem('logoutTimeout', response.message.logoutTimeout)
                }
                // set interval for every 2 min checking session
                this.auth = setInterval(() => {
                  this.Auth();
                }, 120000)
                if(response.message.role == 'manager'){
                  this.$router.push("/manager/userlist");
                  location.reload();
                }
                else if(response.message.role == 'moderator'){
                  this.$router.push("/moderator/userlist");
                }
                else if(response.message.isactive == false){
                  this.$router.push("/pages/verifyaccount");
                }
                else{
                this.$router.push('/')
                }
                if(!window.localStorage.getItem('user_guide')){
                window.localStorage.setItem('user_guide', true)
                }
                else{
                  window.localStorage.setItem('user_guide', false)
                }
                // location.reload();
                // window.location = "/";
              }
              else {
                Swal.fire({title: 'Error!',text: response.message,icon: 'error',confirmButtonText: 'Close',position: 'top-end',showConfirmButton: false,timer: 2000})
                // this.errorNotify('Error','Incorrect username & password','danger')
              }
              this.loading = false
            }
          },
         async Auth() {
          let  response = await LoginService.authenticate()
          if(response != true){
            clearInterval(this.auth)
            this.logout()
          }
        },
        async logout() {
          let response = await LoginService.logout()
              window.localStorage.setItem("user", null);
              window.localStorage.setItem("status", null);
              window.localStorage.setItem("email", null);
              window.localStorage.setItem("iwitness", 'false');
              this.$router.push("/pages/login");
          },
        togglePassword(id){
          const password = document.getElementById(id);
          this.showPassword = this.showPassword === false ? true: false
          const type = password.getAttribute('type') === 'password' ? 'text' : 'password';
          password.setAttribute('type', type);
        }
        }
}
</script>
